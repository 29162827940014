import {
  faBriefcase,
  faGraduationCap,
  faHandsHelping,
  faUsers,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import {
  Card,
  Col,
  Container,
  Jumbotron,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap"
import * as styles from "./index.module.scss"
import Layout from "../../components/layout"

export default function HiImMark() {
  return (
    <Layout>
      <Jumbotron className="bg-secondary mb-0 p-4 text-center" fluid>
        <FontAwesomeIcon
          className="text-light-green"
          icon={faHandsHelping}
          size="6x"
        />
        <h1 className="fw-bold h2 mt-4">Hi, I'm Mark</h1>
        <h2 className="h5 mt-3 text-light-green">
          Professional Background and Community Engagement
        </h2>
      </Jumbotron>
      <Container className="p-4 ">
        <h1 className="fw-bold h4">A Little About Me</h1>
        <Row className="justify-content-center mt-3">
          <Col className="p-md-3" md={6} lg={4}>
            <Card className="bg-light-green h-100" text="dark">
              <Card.Body>
                <FontAwesomeIcon
                  className="text-secondary"
                  icon={faGraduationCap}
                  size="6x"
                />
                <Card.Title className="mt-3">Education</Card.Title>
                <Card.Text>
                  <ul className="d-inline-block text-left">
                    <li>University of Alabama School of Law</li>
                    <li>Juris Doctor  1989</li>
                    <li>Law and Psychology Review, Lead Articles Editor</li>
                    <li>Moot Court Board</li>
                    <li>University of Alabama Culverhouse College of Commerce and Business Administration</li>
                    <li>Bachelor of Science, Commerce and Business Administration, Magna cum Laude, 1989</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="mt-3 mt-md-0 p-md-3" md={6} lg={4}>
            <Card bg="secondary" className="h-100">
              <Card.Body>
                <FontAwesomeIcon
                  className="text-light-green"
                  icon={faBriefcase}
                  size="6x"
                />
                <Card.Title className="mt-3">Experience</Card.Title>
                <Card.Text>
                  <ul className="d-inline-block text-left">
                    <li>LexisNexis Group, Charlottesville, VA:  In-House Attorney (1992-1996)</li>
                    <li>Whitfield &amp; McAlpine, P.C., Mobile, AL:  Shareholder (until 2005)</li>
                    <li>Mark Sterling Gober, LLC (2005-present)</li>
                  </ul>
                  <a></a>
                  Over the past three decades, Mark has distinguished himself as a formidable advocate in 
                  complex, high asset divorce and child custody litigation.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="mt-3 mt-md-0 p-md-3" md={6} lg={4}>
            <Card bg="white" className="h-100" text="dark">
              <Card.Body>
                <FontAwesomeIcon
                  className="light-green"
                  icon={faUsers}
                  size="6x"
                />
                <Card.Title className="mt-3">Community Engagement</Card.Title>
                <Card.Text>
                  Mark serves as Vice President of the Tuscaloosa County Bar Association and  is a member of the Domestic Relations Committee. Mark is involved in several animal welfare charities, is a member of Indian 
                  Hills Country Club and attends Canterbury Episcopal Church. He enjoys travel, amateur photography 
                  and quality time with his three spoiled cats, Jude, LilyBird and Piper.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container className="bg-secondary mb-0 p-4 p-md-5">
        <h1 className="fw-bold h4">All roads lead back to Tuscaloosa</h1>
        <Tabs justify fill className="mt-3" defaultActiveKey="current-practice">
          <Tab
            tabClassName={`text-dark ${styles.tab}`}
            eventKey="undergraduate-studies"
            title="University of Alabama"
          >
            <p className="mt-3 mb-0">
              Mark is a 1986 Graduate of the Culverhouse College of Commerce and
              Business Administration at the University of Alabama. During his
              undergraduate studies, Mark was the recipient of the prestigious
              Hohenberg Trust Scholarship and the Culverhouse Dean’s Award for
              Academic Excellence. Mark was tapped for membership in Mortar
              Board (the premier national honor society recognizing college
              seniors for superior achievement in scholarship, leadership and
              service) and initiated into the Golden Key National Honour Society
              (recognizing students who rank in the top 15% of their class
              within all fields of study). Mark was also chosen for membership
              in Beta Gamma Sigma (the highest recognition a student studying
              business administration can receive in a business program
              accredited by the AACSB), Mu Kappa Tau (academic top 10% of
              students in the marketing discipline), Gamma Beta Phi (academic
              top 10% of sophomore class in all disciplines), Phi Eta Sigma
              (academic top 10% of freshman class in all disciplines) and Alpha
              Lambda Delta (academic top 10% of freshman class in all
              disciplines).
            </p>
          </Tab>
          <Tab
            tabClassName={`text-dark ${styles.tab}`}
            eventKey="law-school"
            title="University of Alabama School of Law"
          >
            <p className="mt-3 mb-0">
              Mark attended law school at the University of Alabama School of Law. 
              While pursuing his studies at UA Law, Mark was selected to
              serve as a teaching member of the John A. Campbell Moot Court
              Board and received the 1988 John A. Campbell Final Round Oralist
              Award (an award recognizing the four law students who advanced to
              the final oral argument round of the John A. Campbell Moot Court
              Competition). Mark was inducted into the Bench and Bar Legal Honor
              Society (an honor limited to 10% of the law school student body
              based upon academic achievement and service to the law school) and
              served as Exchequer for the Phi Delta Phi International Legal
              Fraternity. Mark also acted as the Lead Articles Editor for the
              Alabama Law and Psychology Review.
            </p>
          </Tab>
          <Tab
            tabClassName={`text-dark ${styles.tab} centered-tab-title`}
            eventKey="early-practice"
            title="Early Practice"
          >
            <p className="mt-3 mb-0">
              In 1992, Mark left his first position as an associate at the Mobile, 
              Alabama law firm of  Sintz, Campbell, Duke, Taylor &amp; Cunningham to 
              pursue a new challenge as an In-House Attorney/Legal Editor with LexisNexis
              Group, a multinational corporation providing computer-assisted
              legal research services and maintaining the world’s largest
              electronic database for legal and public records related
              information. While in that position, Mark was based in
              Charlottesville, Virginia where he established contacts and
              relationships with attorneys and legal professionals from all over
              the United States. Many of these professional relationships have
              remained strong and have proved invaluable over the years. Mark
              returned to Alabama in 1995 and joined the Mobile law firm of
              Whitfield and McAlpine, P.C. as a Member/Shareholder. During more
              than a decade of practice in Mobile, Mark distinguished himself as
              a formidable advocate in matters involving complex high asset
              divorce and child custody litigation.
            </p>
          </Tab>
          <Tab
            tabClassName={`text-dark ${styles.tab} centered-tab-title`}
            eventKey="current-practice"
            title="Current Practice"
          >
            <p className="mt-3 mb-0">
              The practice was established in Tuscaloosa, Alabama in 2005. Since
              that time, Mark Sterling Gober, LLC has become well known
              throughout West Central Alabama for providing personal legal
              representation which is second to none. The firm has been listed
              among Lawyers of Distinction (as published in The New York Times,
              CNN, The Huffington Post and USA Today). Attorney and Practice
              Magazine has designated Mark Sterling Gober, LLC as one of
              Alabama’s Top 10 Family Law Practices. The practice has also been
              recognized by the National Association of Family Law Attorneys for
              exceptional and outstanding client representation in matters
              involving child custody, divorce and family law.
            </p>
          </Tab>
          <Tab
            tabClassName={`text-dark ${styles.tab}`}
            eventKey="community-engagement"
            title="Community Engagement"
          >
            <p className="mt-3 mb-0">
              Mark is licensed to practice in Alabama, Louisiana and the District
              of Columbia. He is active in the Tuscaloosa County Bar Association
              and currently serves as it's Vice President and is a member of the Domestic
              Relations Committee. Mark especially enjoys representing
              the legal interests of children as a court appointed Guardian ad
              Litem. Mark is a member of The University Club, Indian Hills Country Club 
              and attends Canterbury Episcopal Church. In his spare time,
              he is an amateur photographer, a frustrated poet and an avid
              international traveler. He enjoys following politics, Crimson Tide
              football, his beloved “Million Dollar Band” and spending time with
              his three high maintenance cats: Jude, LilyBird and Piper.
            </p>
          </Tab>
        </Tabs>
      </Container>
    </Layout>
  )
}
